import {Component, ViewChild, OnInit, ChangeDetectorRef, ElementRef, Input, SimpleChanges} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RecordApplicationService } from '@data/services/api/protected/record-application.service';
import { file } from '@data/interfaces/api/api/record';
import { UiService } from '@shared/services/ui.service';
import { FireStorageService } from '@data/services/api/protected/fire-storage.service';
import { DocumentService } from '@data/services/api/protected/document.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-seguimiento-expedientes-general',
  templateUrl: './seguimiento-expedientes-general.component.html',
  styleUrls: ['./seguimiento-expedientes-general.component.css']
})
export class SeguimientoExpedientesGeneralComponent implements OnInit {
  
  @Input() statusInfo!: any;

  ngOnChanges(changes: SimpleChanges) {
    try {
      this.ngOnInit(); 
    } catch (error) {}  
  }

  ELEMENT_DATA: any;
  displayedColumns: string[] = ['numero_folio_sare', 'nombre_comercial', 'id']; //, 'porcentaje'
  dataSource!: MatTableDataSource<file>; 
  userRolId: any = localStorage.getItem('rolId');
  
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator | any;
  @ViewChild(MatSort,{static:false}) sort: MatSort | any;
  clickedRows = new Set<file>(); 

  constructor(
    private recordService: RecordApplicationService, 
    private cdr: ChangeDetectorRef,
    private uiService: UiService,
    private storageService: FireStorageService,
    private documentService: DocumentService,
    private formBuilder: FormBuilder
  ) { }
  ngAfterViewInit() {
    
  }
  ngOnInit(): void {
    this.initializeRecordsTable();
  }

  
  async initializeRecordsTable(){
    let responseService:any = await this.recordService.getAllByStatus(this.statusInfo.validStatus);
    if(responseService?.ok) {
      this.ELEMENT_DATA =  responseService?.data;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.cdr.detectChanges();
      this.dataSource.paginator = this.paginator;
      this.cdr.detectChanges();
      this.dataSource.sort = this.sort;
    } else {
      this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.')
    }
  }
  
  applyFilter(filterValue: any) {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

   /******* Metodos para barra de Bitácora *******/
   selectedItem?: file;
   binnacleRecord: any;
   async onSelect(item: file) {
     let responseBinnacle:any = await this.recordService.getBinnacle(item?.id);
     if(responseBinnacle?.ok) {
       this.binnacleRecord = responseBinnacle?.binnacle;
       this.selectedItem = item;
     } else {
       this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.')
     }
   }

  /***********-------- Metodos para modal de ver informacion del expediente ---------*******/

  singleRecordInfo: any;
  async showRecordInfo(idExpediente:number){
    let responseRecordInfo:any = await this.recordService.getOne(idExpediente);
    if(responseRecordInfo?.ok && responseRecordInfo?.file != null) {
      this.singleRecordInfo = responseRecordInfo;
      this.originalRecordInfo = responseRecordInfo.file;
    } else {
      this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.');
    }  
  }
   /****************************** Actualizar estatus *******************************/
   /*async acceptRecord(idExpediente: number, message: string) {
    this.uiService.loading(true, 'Enviando expediente');
    let updateResponse: any = await this.recordService.updateRecordStatus(idExpediente, this.statusInfo.nextStatus, message);
    if (updateResponse?.ok) {
      this.uiService.success('Expediente enviado correctamente.');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      // Verifica si el error viene con el código de estado 422 y el mensaje esperado
      if (updateResponse?.status === 422 && updateResponse?.message === "Problemas para consumo SEI") {
        this.uiService.error('El servicio de firma de la DGSEI parece no estar funcionando correctamente.');
      } else {
        // Muestra el mensaje genérico de error
        this.uiService.error('Ocurrió un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.');
      }
    }
  }*/

    async acceptRecord(idExpediente: number, message: string) {
      this.uiService.loading(true, 'Enviando expediente');
      let updateResponse: any = await this.recordService.updateRecordStatus(idExpediente, this.statusInfo.nextStatus, message);
      if (updateResponse?.ok) {
        this.uiService.success('Expediente enviado correctamente.');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        // Verifica si el error viene con el código de estado 422 y el mensaje esperado
        if (updateResponse?.message?.error?.status === 422) {
          this.uiService.error('El servicio de firma de la DGSEI parece no estar funcionando correctamente.');
        } else {
          this.uiService.error('Ocurrió un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.');
        }
      }
    }
  
   /*************** Carga de documentos a Firestorage ****************/
   @ViewChild('documentInput')
   documentInput!: ElementRef;
 
   dataFileUpload:any = {
     id_expediente: 0,
     folioSare: '',
     idDocument:0,
     name:'',
     file: null
   }
   setFileInfo(folioSare:string, id_expediente:number ,id:number, nombre: string){
    this.dataFileUpload.folioSare = folioSare.replaceAll('/','-');
    this.dataFileUpload.id_expediente = id_expediente;
    this.dataFileUpload.idDocument = id;
    this.dataFileUpload.name = this.normalizeStringName(nombre);
  }

  uploadFile(event:any){
    this.dataFileUpload.file = event.target.files[0];
    this.dataFileUpload.name = this.dataFileUpload.name + '.' + this.dataFileUpload.file.type.split('/')[1];
    if(typeof this.dataFileUpload.file !== 'undefined') {
      this.uploadToFirestorage();
    }
  }

  async uploadToFirestorage(){ 
    this.uiService.loading(true, 'Subiendo archivo');
    this.getBase64(this.dataFileUpload.file).then( async data => {
      this.storageService.uploadFile(this.dataFileUpload.name, `expedientes/${this.dataFileUpload.folioSare}/`, data).then(async responseStorage =>{
        if(responseStorage != null){ 
          let documentIsUpload:any = await this.documentService.updateDocumentsById(this.dataFileUpload.idDocument, responseStorage);
          if(documentIsUpload?.ok){
            this.uiService.success('El archivo se subió correctamente.');
            this.documentInput.nativeElement.value = "";
            this.showRecordInfo(this.dataFileUpload.id_expediente);
          } else {
            
            this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor inténtelo más tarde.');
          }
        } else {
          this.uiService.success('Ocurrio un error al procesar la solicitud, por favor inténtelo más tarde.');
        } 
      });
    });
  }
  /***************** Metodos para la observacion de rechazo en expediente ********************/
  sendIdRecord: any;
  setSendIdRecordConfirmation(idRecord: number){
    this.sendIdRecord = idRecord;
  }

  formReject: FormGroup = this.formBuilder.group({
      motivo_rechazo:         [null, [Validators.required]],
  });

  rejectInformation = {
    idExpediente: 0,
    idStatus: 7
  };

  setRejectInfo(idExpediente: number, idStatus:number){
    this.rejectInformation.idExpediente = idExpediente;
    this.rejectInformation.idStatus = idStatus;
    
  }


  async onSubmit() {
    if(this.formReject.valid) {           
      let observationReject = this.formReject.controls['motivo_rechazo'].value;
      this.uiService.loading(true, 'Generando solicitud');
      let updateResponse: any = await this.recordService.updateRecordStatus(this.rejectInformation.idExpediente, this.rejectInformation.idStatus, observationReject);
      if(updateResponse?.ok) {
        this.uiService.success('Expediente rechazado correctamente.');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.');
      } 
        
    } else {
      this.uiService.success('Debe agregar un motivo de rechazo.');
    }
  }

  async signAllApplications (){
    this.uiService.loading(true, 'Firmando expedientes. Esto podria demorar unos minutos');
    let response: any = await this.recordService.signAllApplications();
    
    if(response?.ok) {
      this.uiService.success('Los expedientes fueron firmados correctamente.');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.');
    } 
  }

  /********************* Variable para paso de informacion de formulario expediente****************************/
  originalRecordInfo:any;
  /***************** Auxiliares para la carga de documentos ***********************/

  normalizeStringName = (str:string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(' ').join('_');
  } 

  getBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  setActiveButton(id: string){
    let documento = document.getElementById('documentos-tab') as HTMLElement;
    let expediente = document.getElementById('expediente-tab') as HTMLElement;
    switch(id){
      case 'expediente-tab':
        documento.removeAttribute('class');
        expediente.removeAttribute('class');
        documento.setAttribute('class', 'btn-nav-item');
        expediente.setAttribute('class', 'btn-nav-item-active'); 
      break;
      case 'documentos-tab':
        expediente.removeAttribute('class');
        documento.removeAttribute('class');
        expediente.setAttribute('class', 'btn-nav-item');
        documento.setAttribute('class', 'btn-nav-item-active'); 
      break;
      default:
      break;
    }
  }


}
