
<div>
   <img class="entity-logo " src="/assets/h-color.png"/>

   <h4 class="name">Huixquilucan</h4>

   <!--<mat-divider></mat-divider>-->

   <button mat-button class="menu-button">
      <a href="/user/home">
         <i class="fa-solid fa-house"></i>
         <span>Inicio</span>
      </a>
   </button>
   
   <button mat-button class="menu-button">
      <a href="/user/solicitud">
         <i class="fa-solid fa-sheet-plastic"></i>
         <span>Nueva solicitud </span>
      </a>
   </button>
   <button mat-button class="menu-button">
      <a href="/user/seguimiento">
         <i class="fa-solid fa-eye"></i>
         <span>Seguimiento</span>
      </a>
   </button>
   <button mat-button class="menu-button">
      <a href="/user/perfil">
         <i class="fa-solid fa-user"></i>
         <span>Actualizar Perfil</span>
      </a>
   </button>

   <div class="logout-button">
      <button (click)="logout()" mat-button> Salir </button>
   </div>
</div>