import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl } from '@angular/forms';
import { initialInfoResponse, file, itemListRecordResponse, Record, recordBinnacle, recordBinnacleResponse, RecordResponse, responseGetRecord, singleRecord, UpdateRecord, UpdateRecordResponse, itemListStatusResponse, RecordsHistory } from '@data/interfaces/api/api/record';

@Injectable({
  providedIn: 'root'
})
export class RecordApplicationService {
  private url: string = environment.url;
  private api_key: any = localStorage.getItem('token');
  private user_id: any = localStorage.getItem('user_id');
  private headers: HttpHeaders = new HttpHeaders({ 'x-token': this.api_key });

  constructor(private http: HttpClient, private auth: AngularFireAuth) { }

  async createRecord(control: AbstractControl) {
    
    const endPoint = `${this.url}/file/registerFile`;
    let saturday = control.get('horario_fin_semana1')?.value == 'no' ? '' : control.get('horario_fin_semana1')?.value;
    let sunday= control.get('horario_fin_semana2')?.value == 'no' ? '' : control.get('horario_fin_semana2')?.value;    
      
    let user = await firstValueFrom(this.auth.user);
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      try {
        let body: Record = {
          apellido_paterno: control.get('apellido_paterno')?.value,
          apellido_materno: control.get('apellido_materno')?.value,
          nombres: control.get('nombres')?.value,
          tipo_de_representante: control.get('tipo_de_representante')?.value, 
          id_catalogo_tipo_persona: parseInt(control.get('id_catalogo_tipo_persona')?.value),
          rfc: control.get('rfc')?.value,
          nombre_comercial: control.get('nombre_comercial')?.value,
          razon_social: control.get('razon_social')?.value,
          calle: control.get('calle')?.value,
          id_address: control.get('id_address')?.value,
          numero_exterior: control.get('numero_exterior')?.value,
          numero_interior: control.get('numero_interior')?.value,
          email: control.get('email')?.value,
          telefono_fijo: control.get('telefono')?.value,
          telefono_celular: control.get('celular')?.value,
          clave_catastral: control.get('clave_catastral')?.value,
          clave_agua: control.get('clave_agua')?.value,
          sector: control.get('sector')?.value,
          id_giro: parseInt(control.get('id_giro')?.value.split('-')[0]),
          monto_inversion: control.get('monto_inversion')?.value,
          tipo_de_inversion: control.get('tipo_de_inversion')?.value,
          tamanio: control.get('tamanio')?.value,
          empleos_existentes: control.get('empleos_existentes')?.value,
          empleos_a_generar: control.get('empleos_a_generar')?.value,
          superficie: control.get('superficie')?.value,
          cajones_estacionamiento: control.get('cajones_estacionamiento')?.value,
          horario_semanal: control.get('inicioES')?.value + ' a ' + control.get('finES')?.value,
          inicio_hs: control.get('inicio_hs')?.value,
          fin_hs: control.get('fin_hs')?.value,
          horario_fin_semana: saturday + ',' + sunday,
          inicio_hfs: control.get('inicio_hfs1')?.value + ',' + control.get('inicio_hfs2')?.value,
          fin_hfs: control.get('fin_hfs1')?.value +  ',' + control.get('fin_hfs2')?.value,
          anuncios: parseInt(control.get('anuncios')?.value),
          servicio_empleo: parseInt(control.get('servicio_empleo')?.value),
          id_usuario: parseInt(this.user_id)
        };
       /*  console.log('data: ', body) */
        let recordResponse = await firstValueFrom(this.http.post<RecordResponse>(endPoint, body, { headers: this.headers }));
        resolve(recordResponse);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async updateRecord(control: AbstractControl, idExpediente: number) {
    let user = await firstValueFrom(this.auth.user);
    let saturday = control.get('horario_fin_semana1')?.value == 'no' ? '' : control.get('horario_fin_semana1')?.value;
    let sunday= control.get('horario_fin_semana2')?.value == 'no' ? '' : control.get('horario_fin_semana2')?.value; 
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      try {
        const endPoint = `${this.url}/file/update`;
        let body: Record = {
          id: idExpediente,
          apellido_paterno: control.get('apellido_paterno')?.value,
          apellido_materno: control.get('apellido_materno')?.value,
          nombres: control.get('nombres')?.value,
          tipo_de_representante: control.get('tipo_de_representante')?.value, 
          id_catalogo_tipo_persona: parseInt(control.get('id_catalogo_tipo_persona')?.value),
          rfc: control.get('rfc')?.value,
          nombre_comercial: control.get('nombre_comercial')?.value,
          razon_social: control.get('razon_social')?.value,
          calle: control.get('calle')?.value,
          id_address: control.get('id_address')?.value,
          numero_exterior: control.get('numero_exterior')?.value,
          numero_interior: control.get('numero_interior')?.value,
          email: control.get('email')?.value,
          telefono_fijo: control.get('telefono')?.value,
          telefono_celular: control.get('celular')?.value,
          clave_catastral: control.get('clave_catastral')?.value,
          clave_agua: control.get('clave_agua')?.value,
          sector: control.get('sector')?.value,
          id_giro: parseInt(control.get('id_giro')?.value.split('-')[0]),
          monto_inversion: control.get('monto_inversion')?.value,
          tipo_de_inversion: control.get('tipo_de_inversion')?.value,
          tamanio: control.get('tamanio')?.value,
          empleos_existentes: control.get('empleos_existentes')?.value,
          empleos_a_generar: control.get('empleos_a_generar')?.value,
          superficie: control.get('superficie')?.value,
          cajones_estacionamiento: control.get('cajones_estacionamiento')?.value,
          horario_semanal: control.get('inicioES')?.value + ' a ' + control.get('finES')?.value,
          inicio_hs: control.get('inicio_hs')?.value,
          fin_hs: control.get('fin_hs')?.value,
          horario_fin_semana: saturday + ',' + sunday,
          inicio_hfs: control.get('inicio_hfs1')?.value + ',' + control.get('inicio_hfs2')?.value,
          fin_hfs: control.get('fin_hfs1')?.value +  ',' + control.get('fin_hfs2')?.value,
          anuncios: parseInt(control.get('anuncios')?.value),
          servicio_empleo: parseInt(control.get('servicio_empleo')?.value),
          id_usuario: parseInt(this.user_id)
        };
        let recordResponse = await firstValueFrom(this.http.post<RecordResponse>(endPoint, body, { headers: this.headers }));
        resolve(recordResponse);
        //resolve({ok:true, message: "test"});
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async getAllByUser(){
    let user = await firstValueFrom(this.auth.user);
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      try {
        const endPoint = `${this.url}/file/getAllByUser`;
        let responseRecords = await firstValueFrom(this.http.post<itemListRecordResponse>(endPoint, { uid: user.uid },{ headers: this.headers }));
        /*
        if(responseRecords?.ok) {
          let recordsArray:any = responseRecords?.data;
          responseRecords.data = recordsArray.map((record: any) => ({
            id: record.file.id, 
            id_estatus_expediente: record.file.id_estatus_expediente, 
            numero_folio_sare: record.file.numero_folio_sare, 
            nombre_comercial: record.file.nombre_comercial, 
            porcentaje: record.porcentaje, 
            isCompleted: record.isComplete,
            observacion: record.observacion[0].observacion
          }));
        }*/
        resolve(responseRecords);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }
  
  async getStatus(){    
    return new Promise( async (resolve) => {
      try {
        const endPoint = `${this.url}/statusOfFile/getAll`;
        let responseStatus = await firstValueFrom(this.http.get<itemListStatusResponse>(endPoint,{ headers: this.headers }));       
        resolve(responseStatus);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }
  async getOne(id_expediente:number){
    let user = await firstValueFrom(this.auth.user);
    const endPoint = `${this.url}/file/getOne`;
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      try {
        let responseRecord = await firstValueFrom(this.http.post<responseGetRecord>(endPoint, { id: id_expediente }, { headers: this.headers }));
        resolve(responseRecord);
      } catch (error){
        resolve({ok:false, message: error}); 
      }
    });
  }

  async getAllByStatus(validStatus: number[]){
    let user = await firstValueFrom(this.auth.user);
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      try {
        const endPoint = `${this.url}/file/getAllByStatus`;

        let listRecordsByStatus = await firstValueFrom(this.http.post<itemListRecordResponse>(endPoint, {status:validStatus},{ headers: this.headers }));
        resolve(listRecordsByStatus);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async getAllBy(req: any){
    let user = await firstValueFrom(this.auth.user);
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      try {
        const endPoint = `${this.url}/file/getAllBy`;
        
        let listRecordsByStatus = await firstValueFrom(this.http.post<itemListRecordResponse>(endPoint, req,{ headers: this.headers }));
        resolve(listRecordsByStatus);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async getBinnacle(idBinnacle: number) {
    let user = await firstValueFrom(this.auth.user);
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      const endPoint = `${this.url}/file/binnacle`;
      let recordBinnacle = await firstValueFrom(this.http.post<responseGetRecord>(endPoint, { id: idBinnacle }, { headers: this.headers }));
      try {
        resolve(recordBinnacle);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async getFileData (myStatus: number) {
    
    return new Promise( async (resolve) => {     
      const endPoint = `${this.url}file/getAllFIlesInformation`;
      let fileData = await firstValueFrom(this.http.post<responseGetRecord>(endPoint, { status: myStatus }, { headers: this.headers }));
      try {
        resolve(fileData);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async getInitialInfoNewRecord(){
    let user = await firstValueFrom(this.auth.user);
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      const endPoint = `${this.url}/file/initFile`;
      try {
        let initialInfo = await firstValueFrom(this.http.post<initialInfoResponse>(endPoint,{ uid: user.uid }, { headers: this.headers }));
        resolve(initialInfo);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async updateRecordStatus(id_expediente: number, newStatus: number, observacion?:string){
    const endPoint = `${this.url}/file/newObservation`;
    let body: UpdateRecord = {
      id_expediente: id_expediente,
      id_estatus_expediente: newStatus,
      observacion: observacion
    };
    return new Promise( async (resolve) => {
      try {
        let updateResponse = await firstValueFrom(this.http.post<UpdateRecordResponse>(endPoint, body, { headers: this.headers }));
        resolve(updateResponse);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async getHistoryStats(year:number){
    return new Promise( async (resolve) => {
      const endPoint = `${this.url}/file/getAllByMonthOrYear`;
      try {
        let statsInfo = await firstValueFrom(this.http.post<RecordsHistory>(endPoint,{ anio: year }, { headers: this.headers }));
        resolve(statsInfo);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async getStatsByRange(start: string, end: string){
    return new Promise( async (resolve) => {
      const endPoint = `${this.url}/file/getAllByRange`;
      try {
        let statsInfo = await firstValueFrom(this.http.post<RecordsHistory>(endPoint,{ fecha1: start, fecha2: end }, { headers: this.headers }));
        resolve(statsInfo);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async signAllApplications() {
    return new Promise( async (resolve) => {
      const endPoint = `${this.url}/file/signAllApplications`;
      try {
        /*let response = await firstValueFrom(
          this.http.post<RecordResponse>(endPoint,{ uid: localStorage.getItem('user_id') }, { headers: this.headers })
        );
        resolve(response);*/
        resolve({
          ok: true,
          status: 200,
          message: "Los folios se han firmado correctamente"
        });
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }
}
