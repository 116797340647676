
<div class="card-info-container d-flex justify-content-center aligns-items-center" *ngIf="user?.info.uid">
   <div class="row">      
      <div class="col-4 d-flex justify-content aligns-items-center p-3">
         <div class="profile-picture-container mb-1 mt-2">
            <div class="margin-picture">
               <img class="user-photo"  [src]="user.info.url_foto_perfil != undefined ? user.info.url_foto_perfil : 'assets/imagenes/icons/avatar.png' ">                  
            </div>                       
         </div>            
      </div>
      <div class="col-8 d-flex justify-content-start aligns-items-center p-3">
         <div class="d-flex align-items-center">
            <h3>{{ user.info.nombre }} <span>{{ user.info.apellido_paterno}} {{user.info.apellido_materno}}</span> </h3>
         </div>
      </div>      
      <hr>
      <div class="col-12  d-flex justify-content-start aligns-items-center mb-4 p-3">
         <div class="info-user">
            <p><i class="fa-solid fa-phone"></i> <span>{{ user.info.telefono_celular }}</span></p>
            <p><i class="fa-solid fa-envelope"></i><span>{{ user.info.correo }}</span> </p>
         </div>
      </div>
   </div>
</div>