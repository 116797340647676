import { Component, OnInit } from '@angular/core';
import { UserService } from '@data/services/api/protected/user.service';
import { AuthService } from '@data/services/auth/auth.service';

@Component({
  selector: 'app-user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.css']
})
export class UserInfoCardComponent implements OnInit {
  constructor(private authService:AuthService, private userService: UserService) { }

  user: any;
  async ngOnInit(): Promise<void> {
    this.user = await this.userService.getUser();
    
  }
}
