import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { getStorage, ref} from 'firebase/storage';

firebase.initializeApp(environment.firebase);

@Injectable({
  providedIn: 'root'
})
export class FireStorageService {
  storageRef = firebase.app().storage().ref();
  storage = getStorage();

  constructor(private http: HttpClient) { }

  async uploadFile(name: string, path: string, fileB64: any){
    try{
      //let response = this.storageRef.child("ciudadano/" + path + name).putString(fileB64, "data_url");
      let response = this.storageRef.child(path + name).putString(fileB64, "data_url");
      return (await response).ref.getDownloadURL();
    } catch(err){
      return null;
    }
  }

}
