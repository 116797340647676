import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl } from '@angular/forms';
import { FirebaseError } from '@firebase/util';
import { firstValueFrom } from 'rxjs';
import { UiService } from '@services/api/shared/ui.service'; 
import { environment } from '@env/environment';
import { RegisterResponse } from '@interfaces/auth/register'; 
import { LoginResponse } from '@interfaces/auth/login_response';
import { DefautlResponse } from '@interfaces/auth/default_response';
import { UserResponse } from '@interfaces/auth/user_response';
import  firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private auth: AngularFireAuth,
    private toast: UiService
    
  ) { }
  
  private url: string = environment.url;
  private api_key: string = environment.API_KEY;
  private headers: HttpHeaders = new HttpHeaders({ 'x-api-key': this.api_key });

  async register(control: AbstractControl) {
    let status: boolean = false;
    this.toast.loading(true, 'Registro');
    const endPoint = `${this.url}/user/registerUser`;
    try {
      let user = await this.auth.createUserWithEmailAndPassword(control.get('email')?.value, control.get('password')?.value);
      const body = {       
        uid: user.user?.uid,          
        correo: control.get('email')?.value       
      };          
      let new_user = this.http.post<RegisterResponse>(endPoint, body, {headers: this.headers});
      let respUser = await firstValueFrom(new_user);
      
      this.toast.loading(false, 'Registro');
      if(respUser.ok) {
        this.toast.success('Consulta la bandeja de entrada y de no deseados de tu correo electrónico, da clic en el vínculo para verificar tu correo, después ya podrás ingresar con los datos que registraste.');
        this.sendVerificationEmail();
        localStorage.setItem('token', `${respUser.token}`);
        status = true;
        
      } else {
        await this.auth.signOut();
        
        respUser.errors?.forEach(element => {
          this.toast.error(element);
        });
        status = false;
      }
      return status;
    } catch (err) {
      if (err instanceof FirebaseError) {        
        this.toast.loading(false, 'Registro');
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/email-already-in-use':
            this.toast.error('El correo ya está registrado, intenta con otro.');
          break;
          default:
            this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
          break;
        }
      } else {
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      }
      return false;
    }
  }

  async login(control: AbstractControl) {
    this.toast.loading(true, 'Iniciando sesión');
    const endPoint = `${this.url}/user`;
    try {
      let user = await this.auth.signInWithEmailAndPassword(control.get('email')?.value, control.get('password')?.value);
//  descomentar bloque de codigo para autentiucacion
      if(!user?.user?.emailVerified) {
        this.toast.error('Debe verificar su correo para poder ingresar.');
        return false;
      }
      
      let loginResp = this.http.get<LoginResponse>(`${endPoint}/login/${user.user?.uid}`, {headers: this.headers});
      let resp = await firstValueFrom(loginResp);   
      this.toast.loading(false, 'Iniciando sesión');
      if(resp.user) {
        localStorage.setItem('rolId', `${resp.user.Rol.id}`);
        localStorage.setItem('token', `${resp.token}`);
        localStorage.setItem('user_id', `${resp.user.id}`);
        return resp;
      } else {
        this.toast.error('El correo introducido no completó el registro.');
        await this.auth.signOut();
        return false;
      }
    } catch (err) {
      if (err instanceof FirebaseError) { 
        this.toast.loading(false, 'Iniciando sesión');
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/user-not-found':
            this.toast.error('El correo introducido no está registrado.');
          break;
          case 'auth/wrong-password':
            this.toast.error('La contraseña es incorrecta.');
          break;
          case 'auth/too-many-requests':
            this.toast.error('El acceso a esta cuenta se ha desactivado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede restaurarlo inmediatamente restableciendo su contraseña o puede intentarlo de nuevo más tarde.');
          break;
          default:
            this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
          break;
        }
      } else {        
        await this.auth.signOut();
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      }
      return false;
    }
  }

  async loginwithGoogle(email:string, password: string){
    try {
      this.toast.success('Has iniciado sesión con Google.');
      return await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
      
    } catch (error) {
      
    }
    return false;
  }
  
  async sendVerificationEmail(): Promise<void> {
    return (await this.auth.currentUser)?.sendEmailVerification();
  }

  async forgot(control: AbstractControl) {
    this.toast.loading(true, 'Iniciando sesión');
    const endPoint = `${this.url}/user`;

    try {
      await this.auth.sendPasswordResetEmail(control.get('email')?.value);     
      this.toast.loading(false, 'Iniciando sesión');
      this.toast.success('Se ha enviado un correo para restablecer tu contraseña. Es posible que se encuentre en tu bandeja de spam.');
    } catch (err) {
      if (err instanceof FirebaseError) { 
        this.toast.loading(false, 'Iniciando sesión');
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/user-not-found':
            this.toast.error('El correo introducido no está registrado.');
          break;
          default:
            this.toast.error('Ocurrió un error inesperado, intenta nuevamente.5');
          break;
        }
      } else {
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.6');
      }
    }
  }

  async validToken() {
    let token = localStorage.getItem('token');
    let header = new HttpHeaders({ 'x-token': `${token}` });
    try {
      let res = await firstValueFrom(this.http.post<DefautlResponse>(`${this.url}/token/valid`, {}, { headers: header }));
      return res.ok;
    } catch (error) {
      return false;
    }
  }

  getLogedUser(){
    return this.auth.authState; 
  }

  async logout() {
    localStorage.clear();
    await this.auth.signOut();
  }

  async getUser() {
    const endPoint = `${this.url}/user`;
    let token = localStorage.getItem('token');
    let header = new HttpHeaders({ 'x-token': `${token}` });
    let user = await firstValueFrom(this.auth.user);      

    try {
      let res = await firstValueFrom(this.http.get<UserResponse>(`${endPoint}/${user?.uid}`, { headers: header }));
      return res.user ?? false;
    } catch (err) {
      return true;
      this.toast.error('Ocurrió un error inesperado, intenta nuevamente. 7');
      return false;
    }
  }

}
