import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AbstractControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { CatDocumentsResponse, UpdateDocument, UpdateDocumentResponse } from '@data/interfaces/api/api/document';
import { DocumentsResponse } from '@data/interfaces/api/api/document';
import { DefautlResponse } from '@interfaces/auth/default_response';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  
  private url: string = environment.url;
  private api_key: any = localStorage.getItem('token');
  private headers: HttpHeaders = new HttpHeaders({ 'x-token': this.api_key });

  constructor( private http: HttpClient, private auth: AngularFireAuth) { }

  async getCatDocumentsByPerson() {
    const endPoint = `${this.url}/documentsCatalog/getOne`;
    return new Promise( async (resolve) => {
      try {
        let  responseOne = await firstValueFrom(this.http.get<CatDocumentsResponse>(`${endPoint}/1`, {headers: this.headers}));
        let  responseTwo = await firstValueFrom(this.http.get<CatDocumentsResponse>(`${endPoint}/2`, {headers: this.headers}));
        responseOne.ok != true && responseTwo.ok != true ? resolve(false): resolve({
          personaFisica: responseOne.catalogo_documento, 
          personaMoral: responseTwo.catalogo_documento 
        });
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  async updateDocumentsById(id_documento: number, url_documento: string){
    let user = await firstValueFrom(this.auth.user);
    const endPoint = `${this.url}/document/update`;
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve(false);
      try {
        let body: UpdateDocument [] = [{
          uid: user.uid,
          id_documento: id_documento,
          url_documento: url_documento
        }];
        let documentResponse = await firstValueFrom(this.http.patch<UpdateDocumentResponse>(endPoint, body, { headers: this.headers }));
        resolve(documentResponse);
      } catch (error){
        resolve({ok:false, message: error});
      }
    });
  }

  /*******************************  Antiguos: Abraham borrar al depurar *******************************/
  
  async getDocuments(fid: string) {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({ 'x-token': `${token}` });
    return await firstValueFrom(this.http.get<DocumentsResponse>(`${this.url}/document/${fid}`, { headers: headers }));
  }

  async openDoc(path: string) {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({ 'x-token': `${token}` });
    let doc = await firstValueFrom(this.http.get(`${this.url}/${path}`, { headers: headers, responseType: "blob" }));
    let fileUrl = window.URL.createObjectURL(doc);
    window.open(fileUrl, '_blank');
  }

  async generateLine(fid: string) {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({ 'x-token': `${token}` });
    return await firstValueFrom(this.http.get<DefautlResponse>(`${this.url}/document/getPaidLine/${fid}`, { headers: headers }));
  }

  async getStatusLine(fid: string) {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({ 'x-token': `${token}` });
    return await firstValueFrom(this.http.get<DefautlResponse>(`${this.url}/document/statusPaidLine/${fid}`, { headers: headers }));
  }

  async attachFiles(control: any, fid: string) {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({ 'x-token': `${token}` });
    let body = new FormData();
    for(var key in control.controls) {
      body.append(key, this.dataURItoBlob(control.get(key)?.value), `${key}.pdf`);
    }
    return await firstValueFrom(this.http.post<DefautlResponse>(`${this.url}/document/upload/${fid}`, body, { headers: headers }));
  }

  dataURItoBlob(dataURI: string) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeString});
  }

}
