import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from  '@angular/common/http';
import * as fromComponents from './components';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { NotificationsBtnComponent } from './components/notifications-btn/notifications-btn.component';
import { UserSessionBtnComponent } from './components/user-session-btn/user-session-btn.component';
import { UserInfoCardComponent } from './components/user-info-card/user-info-card.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { InstructionsGeneralComponent } from './components/instructions-general/instructions-general.component';
import { SideCardMenuGeneralComponent } from './components/side-card-menu-general/side-card-menu-general.component';
import { DigitalProfileGeneralComponent } from './components/digital-profile-general/digital-profile-general.component';
import { FormRecordInfoComponent } from './components/form-record-info/form-record-info.component';
import { SeguimientoExpedientesGeneralComponent } from './components/seguimiento-expedientes-general/seguimiento-expedientes-general.component';
import { DocumentsWarningComponent } from './components/documents-warning/documents-warning.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatDialogModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UserSessionBtnComponent,
    UserInfoCardComponent,
    SideBarComponent,
    HeaderBarComponent,
    InstructionsGeneralComponent, 
    DigitalProfileGeneralComponent,
    SideCardMenuGeneralComponent,
    FormRecordInfoComponent,
    SeguimientoExpedientesGeneralComponent,
    ...fromComponents.components
  ],
  declarations: [...fromComponents.components, SideBarComponent, HeaderBarComponent, NotificationsBtnComponent, UserSessionBtnComponent, UserInfoCardComponent, InstructionsGeneralComponent, SideCardMenuGeneralComponent, DigitalProfileGeneralComponent, FormRecordInfoComponent, SeguimientoExpedientesGeneralComponent, DocumentsWarningComponent],
  
})
export class SharedModule { }
