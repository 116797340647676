import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '@env/environment';
import { User, UserResponse, UpdateUserResponse } from '@data/interfaces/api/api/users';
import { firstValueFrom } from 'rxjs';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private auth: AngularFireAuth) { }

  private url: string = environment.url;
  private api_key: any = localStorage.getItem('token');
  private headers: HttpHeaders = new HttpHeaders({ 'x-token': this.api_key });

  async getUser(){
    const endPoint = `${this.url}/user/getOne`;
    let user = await firstValueFrom(this.auth.user);  
    return new Promise( async (resolve) => {
        if(!user?.uid) return resolve(false) 
        let  userResponse = await firstValueFrom(this.http.post<UserResponse>(`${endPoint}`, {'uid': user?.uid},{headers: this.headers}));
        userResponse.ok != true ? resolve(false): resolve({info: userResponse.user, photoURL: user.photoURL });
    });
  }

  async updateUser(control: AbstractControl){
    let user = await firstValueFrom(this.auth.user); 

    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve({ ok: false }) 
      try {
        const endPoint = `${this.url}/user/update`;
        let body: User = {
          uid: user?.uid,
          nombre: control.get('nombres')?.value,
          apellido_paterno: control.get('apellido_paterno')?.value,
          apellido_materno: control.get('apellido_materno')?.value,
          telefono_fijo: control.get('telefono_fijo')?.value,
          telefono_celular: control.get('telefono_celular')?.value,
          correo_de_notificacion: control.get('email')?.value
        };
        let  userResponse = await firstValueFrom(this.http.post<UpdateUserResponse>(`${endPoint}`, body,{headers: this.headers}));
        userResponse?.ok == false ? resolve({ok: false, mesagge: "Error inesperado al actualizar la información"}): resolve(userResponse);
      } catch (error){
        resolve({ok:false, message: error});
      }
  
    });
  }

  async updateImageProfile(url: string){
    let user = await firstValueFrom(this.auth.user); 
    return new Promise( async (resolve) => {
      if(!user?.uid) return resolve({ ok: false }) 
      try {
        const endPoint = `${this.url}/user/updateProfile`;
        const body = { uid: user?.uid, url_foto_perfil: url };
        let  userResponse = await firstValueFrom(this.http.post<UpdateUserResponse>(`${endPoint}`, body,{headers: this.headers}));
        userResponse?.ok == false ? resolve({ok: false, mesagge: "Error inesperado al actualizar la información"}): resolve(userResponse);
      } catch (error){
        resolve({ok:false, message: error});
      }
  
    });
  }
}
