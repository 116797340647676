import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiService } from '@shared/services/ui.service';
import { RecordApplicationService } from '@data/services/api/protected/record-application.service';

@Component({
  selector: 'app-form-record-info',
  templateUrl: './form-record-info.component.html',
  styleUrls: ['./form-record-info.component.css']
})
export class FormRecordInfoComponent implements OnInit {
  //Record info from fhater component
  @Input() originalRecordInfo: any;
  validEditStatus: Number[] = [1,6];
  saturday: boolean = false;
  sunday: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private uiService: UiService, 
    private recordApplication: RecordApplicationService,
  ) { }
  
  ngOnChanges(changes: SimpleChanges) {
    try {
      this.load(); 
    } catch (error) {}  
  }
 
  initialInfo: any; 
  fromIsActive: boolean = false;
  formRecordInfo: FormGroup = this.formBuilder.group({
    // Datos personales 
    apellido_paterno:         [null, [Validators.required]],
    apellido_materno:         [null, [Validators.required]],
    nombres         :         [null, [Validators.required]],
    tipo_de_representante:      [null, [Validators.required]],
    id_catalogo_tipo_persona: [null, [Validators.required]],
    rfc:                      [null, [Validators.required]], //, Validators.minLength(13), Validators.maxLength(13)
    nombre_comercial:         [null, [Validators.required]],
    razon_social:             [null],
    // Dirección y Datos de contacto de la Empresa 
    calle:                    [null, [Validators.required]],
    id_address:                  [null, [Validators.required]], // No en Service 
    cp:                       [null, [Validators.required]], // No en Service 
    numero_exterior:          ['--'],
    numero_interior:          ['--'],
    telefono:                 [null, [Validators.required]], // No en Service
    email:                    [null, [Validators.required, Validators.email]], 
    celular:                  [null, [Validators.required,]], // No en Service 
    // Datos de la Empresa 
    // Predio                         095-99-999-99-AAAAAA. 
    clave_catastral:          [null, [Validators.required]], //, Validators.minLength(20)
    clave_agua:               [null, [Validators.required]], //, Validators.minLength(20)
    sector:                   [null, [Validators.required]],
    id_giro:                  [null, [Validators.required]],
    monto_inversion:          [null, [Validators.required]],
    tipo_de_inversion:        [null, [Validators.required]],
    tamanio:                  [null, [Validators.required, Validators.min(1)]],
    empleos_existentes:       [null, [Validators.required]],
    empleos_a_generar:        [null, [Validators.required]],
    superficie:               [null, [Validators.required]],
    cajones_estacionamiento:  [null, [Validators.required]],
    inicioES:                 [null, [Validators.required]], // inicio rango de horario_semanal
    finES:                    [null, [Validators.required]], //fin rango de horario_semanal
    inicio_hs:                [null, [Validators.required]],
    fin_hs:                   [null, [Validators.required]],
    horario_fin_semana1:      [null, []],
    inicio_hfs1:              [null, []],
    fin_hfs1:                 [null, []],
    horario_fin_semana2:      [null, []],
    inicio_hfs2:              [null, []],
    fin_hfs2:                 [null, []],
    anuncios:                 [null, [Validators.required]],
    servicio_empleo:          [null, [Validators.required]],
   
  });

  async ngOnInit(): Promise<void> {
    this.initialInfo = await this.recordApplication.getInitialInfoNewRecord();
    if(this.initialInfo?.ok){
      this.load();
    } else {
      this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.')
    }   
  }

  activeItems(): any{
    let inputList = document.getElementsByClassName("isEditable");
    let selectlist = document.getElementsByClassName("isDisabled");
    let terminos = document.getElementById('terminos') as HTMLElement;
    try {
      for (let indexI = 0; indexI < inputList.length; ++indexI) {
        inputList[indexI].removeAttribute('readonly');
      }
      for (let index = 0; index < selectlist.length; ++index) {
        selectlist[index].removeAttribute('disabled');
      }
      terminos.removeAttribute('onclick');
    } catch (error) {}  
  }

  desactiveItems(): any{
    var inputList = document.getElementsByClassName("isEditable");
    var selectlist = document.getElementsByClassName("isDisabled");
    let terminos = document.getElementById('terminos') as HTMLElement;
    try {
      for (let indexI = 0; indexI < inputList.length; ++indexI) {
        inputList[indexI].setAttribute('readonly', '');
      }
      for (let index = 0; index < selectlist.length; ++index) {
        selectlist[index].setAttribute('disabled', '');
      }  
      terminos.setAttribute('onclick','return false');
    } catch (error) {}  
  }

  load(): any{
    this.saturday = this.originalRecordInfo?.sabado ? true : false;
    this.sunday = this.originalRecordInfo?.domingo ? true : false;
    if(this.originalRecordInfo){
      let horarioCompleto = this.originalRecordInfo?.horario_semanal.split(" a ");
      const response = {
        apellido_paterno:          this.originalRecordInfo?.apellido_paterno,
        apellido_materno:          this.originalRecordInfo?.apellido_materno,
        nombres:                   this.originalRecordInfo?.nombres,
        telefono:                  this.originalRecordInfo?.telefono_fijo,
        email:                     this.originalRecordInfo?.email,
        celular:                   this.originalRecordInfo?.telefono_celular,
        id_address:                this.originalRecordInfo?.id_address, 
        id_giro:                   `${this.originalRecordInfo?.Giro.id}- ${this.originalRecordInfo?.Giro.giro_comercial}`,
        /*LLenado automatico para pruebas*/
        calle:                     this.originalRecordInfo?.calle,
        cp:                        this.originalRecordInfo?.Address.cp,  
        numero_exterior:           this.originalRecordInfo?.numero_exterior, 
        numero_interior:           this.originalRecordInfo?.numero_interior,
        clave_catastral:           this.originalRecordInfo?.clave_catastral,
        clave_agua:                this.originalRecordInfo?.clave_agua,  
        sector:                    this.originalRecordInfo?.sector,
        empleos_existentes:        this.originalRecordInfo?.empleos_existentes,
        empleos_a_generar:         this.originalRecordInfo?.empleos_a_generar,
        superficie:                this.originalRecordInfo?.superficie,
        cajones_estacionamiento:   this.originalRecordInfo?.cajones_estacionamiento,
        inicioES:                  horarioCompleto[0],
        finES:                     horarioCompleto[1],
        inicio_hs:                 this.originalRecordInfo?.inicio_hs,
        fin_hs:                    this.originalRecordInfo?.fin_hs,
        horario_fin_semana1:       this.originalRecordInfo?.sabado ? 'Sábado' : 'no',
        inicio_hfs1:               this.originalRecordInfo?.sabado_inicio,
        fin_hfs1:                  this.originalRecordInfo?.sabado_fin,
        horario_fin_semana2:       this.originalRecordInfo?.domingo ? 'Domingo' : 'no',
        inicio_hfs2:               this.originalRecordInfo?.domingo_inicio,
        fin_hfs2:                  this.originalRecordInfo?.domingo_fin,
        tipo_de_inversion:         this.originalRecordInfo?.tipo_de_inversion,
        monto_inversion:           this.originalRecordInfo?.monto_inversion,
        tamanio:                   this.originalRecordInfo?.tamanio,
        tipo_de_representante:     this.originalRecordInfo?.tipo_de_representante,
        id_catalogo_tipo_persona:  this.originalRecordInfo?.id_catalogo_tipo_persona,
        rfc:                       this.originalRecordInfo?.rfc,
        nombre_comercial:          this.originalRecordInfo?.nombre_comercial,
        razon_social:              this.originalRecordInfo?.razon_social,
        anuncios:                  this.originalRecordInfo?.anuncios,
        servicio_empleo:           this.originalRecordInfo?.servicio_empleo,
      }
      this.formRecordInfo.patchValue(response);
    }
    
    if(!this.validEditStatus.includes(this.originalRecordInfo?.id_estatus_expediente)){
      this.desactiveItems();
    } else {
      this.activeItems();
    }
  }

  applyRazonSocial(): any{
    let valor = (document.getElementById('id_catalogo_tipo_persona') as HTMLInputElement).value;
    if(valor=='1'){
      document.getElementById('razon_social')?.setAttribute('style', 'visibility:hidden !important;');
    }else{
      document.getElementById('razon_social')?.setAttribute('style', 'visibility:visible !important;');
    }
  }

  validaHorario(hora: number):boolean{
    return  hora >=500 && hora <=2200 ? true : false;
  }
  async onSubmit() {
    
    if(this.formRecordInfo.valid) {      
      let horaEsI = Number((document.getElementById('inicio_hs') as HTMLInputElement)?.value.replace(':',''));
      let horaEsF = Number((document.getElementById('fin_hs') as HTMLInputElement)?.value.replace(':',''));
      let horaFsI1 = 0;   
      let horaFsF1 = 0; 
      let horaFsI2 = 0;
      let horaFsF2 = 0;
      let h1 = this.validaHorario(horaEsI);
      let h2 = this.validaHorario(horaEsF); 
      let h3 = false;
      let h4 = false;    
      let h5 = false;
      let h6 = false;       
      let validadorSaturday: boolean = true;
      let validadorSunday: boolean = true;       
      if(this.saturday){
        horaFsI1 = Number((document.getElementById('inicio_hfs1') as HTMLInputElement)?.value.replace(':',''));   
        horaFsF1 = Number((document.getElementById('fin_hfs1') as HTMLInputElement)?.value.replace(':',''));
        h3 = this.validaHorario(horaFsI1);
        h4 = this.validaHorario(horaFsF1);
        if(!h3){
          let errorH3 = document.getElementById('inicio_hfs1') as HTMLInputElement;
          errorH3.setAttribute('class', 'w-100 font-rr is-invalid');
        }else{
          let errorH3 = document.getElementById('inicio_hfs1') as HTMLInputElement;
          errorH3.setAttribute('class', 'w-100 font-rr is-valid');
        }
        if(!h4){
          let errorH4 = document.getElementById('fin_hfs1') as HTMLInputElement;
          errorH4.setAttribute('class', 'w-100 font-rr is-invalid');
        }else{
         let errorH4 = document.getElementById('fin_hfs1') as HTMLInputElement;
          errorH4.setAttribute('class', 'w-100 font-rr is-valid');
        }
        if(!(h3 && h4 )){
          validadorSaturday = false;             
        }           
      }
      if(this.sunday){
        horaFsI2 = Number((document.getElementById('inicio_hfs2') as HTMLInputElement)?.value.replace(':',''));   
        horaFsF2 = Number((document.getElementById('fin_hfs2') as HTMLInputElement)?.value.replace(':','')); 
        h5 = this.validaHorario(horaFsI2);
        h6 = this.validaHorario(horaFsF2); 
        if(!h5){
          let errorH5 = document.getElementById('inicio_hfs2') as HTMLInputElement;
          errorH5.setAttribute('class', 'w-100 font-rr is-invalid');
       }else{
         let errorH5 = document.getElementById('inicio_hfs2') as HTMLInputElement;
          errorH5.setAttribute('class', 'w-100 font-rr is-valid');
       }
       if(!h6){
         let errorH6 = document.getElementById('fin_hfs2') as HTMLInputElement;
         errorH6.setAttribute('class', 'w-100 font-rr is-invalid');
       }else{
        let errorH6 = document.getElementById('fin_hfs2') as HTMLInputElement;
         errorH6.setAttribute('class', 'w-100 font-rr is-valid');
       }
       if(!(h5 && h6 )){
        validadorSunday = false;             
       }                  
      }        
      if(h1&&h2&&validadorSaturday&&validadorSunday){
        this.uiService.loading(true, 'Generando solicitud');
        let recordResponse:any = await this.recordApplication.updateRecord(this.formRecordInfo, this.originalRecordInfo.id);
        if(recordResponse?.ok){
          this.uiService.success('La solicitud se generó correctamente.');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }else{
          this.uiService.success('Ocurrio un error al generar la solicitud, por favor, inténtelo más tarde.');
        }
        
      }else{
        if(!validadorSaturday){
          this.uiService.success('Por favor ingrese un horario laboral valido para el día sábado.');
        }else if (!validadorSunday){
          this.uiService.success('Por favor ingrese un horario laboral valido para el día domingo.');
        }else{
          this.uiService.success('Si el horario laboral es mayor a las 22:00 hrs o previo a las 5:00 hrs, acudir a ventanilla.');
        }
      }
    } else {
      this.uiService.success('Debe completar el formulario y aceptar los términos y condiciones para continuar');
    }
  }
  updateDay( day_id:string){
    let valor = (document.getElementById(day_id) as HTMLInputElement).value;
    switch(day_id){
      case 'saturday':
        this.saturday = (valor!='no' && valor != null && valor != '') ? true : false;
      break;
      case 'sunday':
        this.sunday = (valor!='no' && valor != null && valor != '') ? true : false;
      break;
    }    
  }
}
