<div class="row mt-4 d-flex justify-content-center">
  <div class="col-lg-7 col-12 mb-4">
     <div class="panel-card scroll scroll--simple" *ngIf="ELEMENT_DATA != undefined">

      <div class="row">
        <div class="col-lg-6 col-12 d-flex justify-content-start align-items-end">
          <h4>Expedientes <span>Validación de trámites</span> </h4> 
        </div>

        <!-- <div class="col-lg-6 col-12 d-flex justify-content-end align-items-end" *ngIf="userRolId == 5">
          <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modalConfirmationSignAll" data-backdrop="false">Firmar todos</button>
        </div> -->

        <div class="col-lg-12 mt-4 col-12 d-flex justify-content-end align-items-end">
          <div class="search">
              <input type="search" matInput (keyup)="applyFilter($any($event.target).value)"  placeholder="Búsqueda de folio">
          </div>
        </div>

      </div>

       <table mat-table [dataSource]="dataSource" class=" demo-table" matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="numero_folio_sare">
          <th mat-header-cell class="th-title" *matHeaderCellDef mat-sort-header>Folio SARE</th>
          <td mat-cell class="td-title" *matCellDef="let element" (click) = "onSelect(element)">{{element.numero_folio_sare}}</td>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="nombre_comercial">
          <th mat-header-cell class="th-title" *matHeaderCellDef mat-sort-header>Nombre</th>
          <td mat-cell class="td-title" *matCellDef="let element" (click) = "onSelect(element)">{{element.nombre_comercial}}</td>
        </ng-container>

        <!-- percentage Column 
        <ng-container matColumnDef="porcentaje">
          <th mat-header-cell class="th-title" *matHeaderCellDef mat-sort-header>Progreso</th>
          <td mat-cell class="td-title" *matCellDef="let element" (click) = "onSelect(element)">
            <div *ngIf="element.id_estatus_expediente != 6 && element.id_estatus_expediente != 7">
              <p>{{element.porcentaje}}% Progreso</p>
              <div class="progress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': element.porcentaje +'%'}" [attr.aria-valuenow]="element.porcentaje+'%'"  aria-valuemin="0" aria-valuemax="100" (click) = "onSelect(element)"></div>
              </div>
            </div>
            <div *ngIf="element.id_estatus_expediente == 6">
              <p>Rechazado con observación</p>
              <div class="progress">
                <div class="progress-bar bg-warning" role="progressbar" [ngStyle]="{'width': '100%'}" [attr.aria-valuenow]="'100%'"  aria-valuemin="0" aria-valuemax="100" (click) = "onSelect(element)"></div>
              </div>
            </div>
            <div  *ngIf="element.id_estatus_expediente == 7">
              <p>Solicitud Cancelada</p>
              <div class="progress">
                <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width': '100%'}" [attr.aria-valuenow]="'100%'"  aria-valuemin="0" aria-valuemax="100" (click) = "onSelect(element)"></div>
              </div>
            </div>
          </td>
        </ng-container>-->

        <!-- Actions Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell class="th-title" *matHeaderCellDef>Acciones</th>
          <!--<td mat-cell class="td-title" *matCellDef="let element">{{element.id}}</td>-->

          <td mat-cell class="td-title" *matCellDef="let element" (click) = "onSelect(element)">
            <div class="d-flex justify-content-center">

              <div class="btn-group d-flex justify-content-center" role="group" aria-label="Button group with nested dropdown">
                <button type="button" class="btn details-record" (click)="showRecordInfo(element.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Ver información del expediente" data-bs-toggle="modal" data-bs-target="#modalInfoRecord" data-backdrop="false">
                  <i class="fa-solid fa-circle-info"></i> Ver
                </button>
                <div class="btn-group" role="group">
                  <button id="btnGroupDrop-{{element.id}}" type="button" class="btn details-actions dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    Acciones
                  </button>
                  <ul class="dropdown-menu" attr.aria-labelledby="btnGroupDrop-{{element.id}}">
                    <li *ngIf="element.isComplete">
                      <a class="dropdown-item confirm-record" href="javascript:void(0)" (click)="setSendIdRecordConfirmation(element.id)"  data-bs-toggle="modal" data-bs-target="#modalConfirmation" data-backdrop="false">
                        <i class="fa-solid fa-check"></i> Confirmar
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item return-record" href="javascript:void(0)" (click)="setRejectInfo(element.id, 6)" data-bs-toggle="modal" data-bs-target="#modalReject" data-backdrop="false">
                        <i class="fa-solid fa-repeat"></i> Regresar
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item reject-record"  href="javascript:void(0)" (click)="setRejectInfo(element.id, 7)" data-bs-toggle="modal" data-bs-target="#modalReject" data-backdrop="false">
                        <i class="fa-solid fa-xmark"></i> Rechazar
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!--<button class="observation-btn" (click)="setRejectMessage(element.observacion)"  *ngIf="element.id_estatus_expediente == 6 || element.id_estatus_expediente == 7" data-bs-toggle="tooltip" data-bs-placement="top" title="Ver motivo"  data-bs-toggle="modal" data-bs-target="#modalRejectMessage" data-backdrop="false">
                <i class="fa-solid fa-message"></i>
              </button>-->
              <button class="send-btn" (click)="setSendIdRecordConfirmation(element.id)"  *ngIf="element.isComplete && (element.id_estatus_expediente == 1 || element.id_estatus_expediente == 6)" data-bs-toggle="tooltip" data-bs-placement="top" title="Enviar expediente" data-bs-toggle="modal" data-bs-target="#modalConfirmation" data-backdrop="false">
                <i class="fa-solid fa-share-from-square"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row

            [class.demo-row-is-clicked]="clickedRows.has(row)"
            *matRowDef="let row; columns: displayedColumns;"
        ></tr>
      </table>
       <mat-paginator hidePageSize="true" [pageSize]="7" [pageSizeOptions]="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"></mat-paginator>
     </div>
  </div>
  <div class="col-lg-4 col-12">
     <div class="panel-card" >
       <h4>Bitácora SARE <span *ngIf="binnacleRecord != undefined">Seguimiento del Folio: <p class="folioTtitle">{{ selectedItem?.numero_folio_sare }}</p></span></h4>
       <div class="container-stepper" *ngIf="binnacleRecord != undefined" >
         <ul class="events w-100">
          <li *ngFor="let binnacleItem of binnacleRecord">
            <time><i class="fa-solid fa-circle-check" [ngClass]="{'checked': binnacleItem.status, 'unchecked':!binnacleItem.status}"></i></time>
            <span>
              <strong [ngClass]="{'checked': binnacleItem.status}">{{binnacleItem.descripcion}}</strong> 
              <p *ngIf="binnacleItem.status">Terminado</p> 
              <p *ngIf="!binnacleItem.status">Pendiente</p> 
            </span>
          </li>

          <li>
            <time datetime="10:03"><i class="fa-solid fa-circle-check unchecked"></i></time>
            <span><strong class="checked">Fin</strong></span>
          </li>
         </ul>
       </div>
     </div>
  </div>
</div>

<!-- Modal -->
<div #modalInfoRecord class="modal fade" id="modalInfoRecord" data-bs-backdrop="false" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalInfoRecordLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-body">
        <!-- Input file -->
        <input #documentInput id="inputFile" style="display: none;" #fileImportInput class="file-input" type="file" accept="application/pdf,image/gif,image/jpeg" multiple  (input)="uploadFile($event)"> <!--(change)="showSelectedFile($event)"-->

        <div class="row">
          <div class="col-10 d-flex justify-content-start">
            <h4 class="modal-info-title" >Detalles del expediente</h4>
          </div>
          <div class="col-2 d-flex justify-content-end">
            <button type="button" class="btn-close x-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="col-12 row d-flex justify-content-center mt-4">
            <ul class="nav nav-tabs d-flex justify-content-center" id="tabRecordInfo" role="tablist">
              <!--<li role="presentation">
                <button class="btn-nav-item" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                  <i class="fa-solid fa-user"></i> Usuario
                </button>
              </li>-->
              <li role="presentation">
                <button class="btn-nav-item-active" id="expediente-tab" data-bs-toggle="tab" data-bs-target="#expediente-subsection" type="button" role="tab" aria-controls="expediente-subsection" aria-selected="true" (click)="setActiveButton('expediente-tab')">
                  <i class="fa-solid fa-folder" ></i> Expediente
                </button>
              </li>
              <li role="presentation">
                <button class="btn-nav-item" id="documentos-tab" data-bs-toggle="tab" data-bs-target="#documentos-sunsection" type="button" role="tab" aria-controls="documentos-sunsection" aria-selected="false" (click)="setActiveButton('documentos-tab')">
                  <i class="fa-solid fa-file"></i> Documentos
                </button>
              </li>
            </ul>

            <div class="col-12  modal-body-scroll scroll--simple">
              <div class="tab-content" id="tabRecordInfoContent">
                <!--<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  ...
                </div>-->
                <div class="tab-pane fade show active" id="expediente-subsection" role="tabpanel" aria-labelledby="expediente-tab">
                  <app-form-record-info [originalRecordInfo] = "originalRecordInfo"></app-form-record-info> 
                </div>
                <div class="tab-pane fade" id="documentos-sunsection" role="tabpanel" aria-labelledby="documentos-tab">
                  <div class="mt-4 mb-4"  *ngIf="singleRecordInfo?.documents">
                    <div class="row col-expediente" *ngFor="let document of singleRecordInfo?.documents">
                      <div class="col-8">
                        <h3 class="titleDocument">{{document.Catalogo_documento.nombre_documento}}<span>{{document.Catalogo_documento.descripcion}}</span></h3>
                      </div>
                      <div class="col-4 d-flex justify-content-center">
                        <!--Upload button -->
                        <a href="javascript:void(0)" *ngIf="document?.Expediente?.Estatus_expediente.id == 3 && (document?.Catalogo_documento?.id == 9 || document?.Catalogo_documento?.id == 23)">
                          <button class="btn-upload-document" data-bs-toggle="tooltip" data-bs-placement="top" title="Subir Documento" (click)="setFileInfo(singleRecordInfo.file.numero_folio_sare, singleRecordInfo.file.id, document?.id, document?.Catalogo_documento?.nombre_documento)">
                            <label for="inputFile"> 
                              <i class="fa-solid fa-cloud-arrow-up"></i>
                            </label>
                          </button>
                        </a>
                        <!-- Watch document -->
                        <a href="{{document?.url_documento}}" target="_blank" *ngIf="document?.url_documento != null">
                          <button class="btn-show-document" data-bs-toggle="tooltip" data-bs-placement="top" title="Ver Documento">
                            <i class="fa-solid fa-eye"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sare" data-bs-dismiss="modal" (click)="initializeRecordsTable()">Cerrar</button>
        <!--<button type="button" class="btn btn-primary">Understood</button>-->
      </div>
    </div>
  </div>
</div>

<!-- Modal de confirmación de envio de expediente -->
<div #modalConfirmation class="modal fade" id="modalConfirmation" data-bs-backdrop="false" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalConfirmationLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">

      <div class="modal-body info-observation text-center">
        <h4>¿Está seguro que desea continuar?</h4> 
      </div>
      <div class="row d-flex justify-content-center mb-4">
        <div class="col-lg-5 col-7 d-flex justify-content-between">
          <button type="button" class="btn btn-success" (click)="acceptRecord(sendIdRecord, '')">Aceptar</button>
          <button type="button" class="btn btn-danger"  data-bs-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal de confirmación de firma masiva de expediente -->
<!-- <div #modalConfirmationSignAll class="modal fade" id="modalConfirmationSignAll" data-bs-backdrop="false" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalConfirmationSignAllLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">

      <div class="modal-body info-observation text-center">
        <h4>¿Está seguro que desea continuar?</h4> 
      </div>
      <div class="row d-flex justify-content-center mb-4">
        <div class="col-lg-5 col-7 d-flex justify-content-between">
          <button type="button" class="btn btn-success" (click)="signAllApplications()">Aceptar</button>
          <button type="button" class="btn btn-danger"  data-bs-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Modal de confirmacion de rechazo -->
<div #modalReject class="modal fade" id="modalReject" data-bs-backdrop="false" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalRejectLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">

      <div class="modal-body info-observation">
        <h4>¿Está seguro que desea continuar con el rechazo?</h4> 

        <form [formGroup]="formReject" (ngSubmit)="onSubmit()" class="p-0 m-0">
          <label for="motivo_rechazo" class="mt-3 d-block">Agregue un motivo de rechazo:</label>
          <textarea class="w-100" name="textarea" rows="4" cols="50" placeholder="Escriba el motivo de rechazo" formControlName="motivo_rechazo">Write something here</textarea>
          <div class="d-flex justify-content-center">
            <button class="btn-sm btn-info mt-3 text-white" type="submit">Rechazar</button>
          </div>
        </form>
        <!--<p>{{ rejectMessage }}</p>-->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-sare" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>
