<div class="row p-0" *ngIf="initialInfo"><!-- && originalRecordInfo-->
    <div class="col-12 font-mb"> 
        <form [formGroup]="formRecordInfo" (ngSubmit)="onSubmit()" class="p-0 m-0">
            <span class="font-mb mt-3">Información del expediente</span>  
            <span class="mt-3 font-rb">Datos personales</span>
            <label for="apellido_paterno" class="mt-3 d-block">Apellido Paterno</label>
            <input class="w-100 isEditable" type="text" placeholder="Apellido paterno" id="apellido_paterno" formControlName="apellido_paterno">
            
            <label for="apellido_paterno" class="mt-3 d-block">Apellido Materno</label>
            <input class="w-100 isEditable" type="text" placeholder="Apellido materno" formControlName="apellido_materno">
            
            <label for="nombres" class="mt-3 d-block">Nombre (s)</label>
            <input class="w-100 isEditable" type="text" placeholder="Nombre(s)" formControlName="nombres" id="nombres">

            <label for="tipo_de_representante" class="mt-3 d-block">Tipo de representante</label>
            <select class="w-100 isDisabled" formControlName="tipo_de_representante">
                <option value="propietario" selected>Propietario</option>
                <option value="representante_legal">Representante legal</option>
            </select>
            
            <label for="id_catalogo_tipo_persona" class="mt-3 d-block">Tipo de persona</label>
            <select class="w-100 isDisabled" name="" formControlName="id_catalogo_tipo_persona" id="id_catalogo_tipo_persona" (click)="applyRazonSocial()" #persona>
                <option value="2"selected>Persona moral</option>
                <option value="1">Persona física</option>
            </select>

            <label for="rfc" class="mt-3 d-block">RFC</label>
            <input class="w-100 isEditable" type="text" placeholder="RFC" formControlName="rfc">

            <label for="nombre_comercial" class="mt-3 d-block">Nombre comercial</label>
            <input class="w-100 isEditable" type="text" placeholder="Nombre Comercial" formControlName="nombre_comercial">  
            
            <!--<label for="razon_social" class="mt-3 d-block">Razón Social</label>-->
            <input class="w-100 isEditable mt-3" type="text" placeholder="Razón Social" formControlName="razon_social" id="razon_social" style="visibility:hidden;">


            <span class="font-mb mt-5">Dirección y Datos de Contacto de la Empresa</span>  
            <span class="mt-5 font-rb">Dirección</span>

            <label for="calle" class="mt-3 d-block">Calle</label>
            <input class="w-100 isEditable" type="text" placeholder="Calle" formControlName="calle">

            <label for="colonia" class="mt-3 d-block">Colonia</label>
            <select class="w-100 isDisabled" formControlName="id_address" id="colonia">
                <option *ngFor="let data of initialInfo.colonias" value="{{data.id}}"> 
                   {{data.colonia}}
                 </option>
            </select>

            <label for="cp" class="mt-3 d-block">Código Postal</label>
            <select class="w-100 isDisabled" formControlName="cp">
                <option *ngFor=" let codigoPostal of initialInfo.codigo_postal; let i = index">
                   {{codigoPostal.cp}}
                 </option>
             </select> 

            <label for="numero_exterior" class="mt-3 d-block">No. Ext.</label>
            <input class="w-100 isEditable" type="text" placeholder="No. Ext." formControlName="numero_exterior">

            <label for="numero_interior" class="mt-3 d-block">No. Int.</label>
            <input class="w-100 isEditable" type="text" placeholder="No. Int." formControlName="numero_interior">

            <span class="mt-5 font-rb">Datos de Contacto</span>

            <label for="telefono" class="mt-3 d-block">Teléfono Fijo</label>
            <input class="w-100 isEditable" type="text" placeholder="Teléfono Fijo" formControlName="telefono">

            <label for="email" class="mt-3 d-block">Correo Electrónico</label>
            <input class="w-100 isEditable" type="text" placeholder="Correo Electrónico" formControlName="email">

            <label for="celular" class="mt-3 d-block">Celular</label>
            <input class="w-100 p-1 font-rr" type="text" placeholder="Celular" formControlName="celular">


            <span class="font-mb mt-5">Datos de la empresa</span>  
            <span class="mt-5 font-rb">Datos predio</span>
            
            <label for="clave_catastral" class="mt-3 d-block">Clave Catastral</label>
            <input class="w-100 isEditable" type="text" placeholder="Clave Catastral" formControlName="clave_catastral">

            <label for="clave_agua" class="mt-3 d-block">Cuenta de Agua</label>
            <input class="w-100 isEditable" type="text" placeholder="Cuenta de Agua" formControlName="clave_agua">

            <label for="sector" class="mt-3 d-block">Sector</label>
            <select class="w-100 isDisabled" formControlName="sector">
                <option value="Industrial" selected>Industrial</option>
                <option value="Comercial">Comercial</option>
                <option value="Servicios">Servicios</option>
                <option value="Mixto">Mixto</option>
            </select>

            <span class="mt-5 font-rb">Giro SARE</span>
            <input class="w-100 isEditable" placeholder="Escriba o seleccione para buscar" id="id_giro" formControlName="id_giro" list="giroSare">
            <datalist id="giroSare"> 
                <option *ngFor="let data of initialInfo.giros" value="{{data.id}}- {{data.giro_comercial}}"></option>                                 
            </datalist>

            <span class="mt-5 font-rb">Tipo de inversión</span>
            <select class="w-100 isDisabled" formControlName="tipo_de_inversion">
                <option value="Nacional" selected>Nacional</option>
                <option value="Extranjera">Extranjera</option>
                <option value="Mixta">Mixta</option>
            </select> 
            
            <span class="mt-5 font-rb">Monto de inversión</span>
            <input class="w-100 isEditable" type="number" placeholder="Monto en MXN" formControlName="monto_inversion">           

            <span class="mt-5 font-rb">Tamaño de la empresa</span>
            <label for="tamanio" class="mt-3 d-block">Tamaño de la empresa</label>
            <select class="w-100 isDisabled" formControlName="tamanio">
                <option value="micro" selected>Micro (0-10)</option>
                <option value="pequeña">Pequeña (11-50)</option>
                <option value="mediana">Mediana (51-250)</option>   
                <option value="grande">Grande (+250)</option> 
            </select> 
           
            <label for="empleos_existentes" class="mt-3 d-block">Empleos existentes</label>
            <input class="w-100 isEditable" type="number" min="0" placeholder="Empleos Existentes" formControlName="empleos_existentes">

            <label for="empleos_a_generar" class="mt-3 d-block">Empleos por generar</label>
            <input class="w-100 isEditable" type="number" min="0" placeholder="Empleos por Generar" formControlName="empleos_a_generar">

            <label for="superficie" class="mt-3 d-block">Superficie a Ocupar m2</label>
            <input class="w-100 isEditable" type="number" min="0" placeholder="Superficie a Ocupar m2" formControlName="superficie">

            <label for="cajones_estacionamiento" class="mt-3 d-block">No. de Cajones de Estacionamiento</label>
            <input class="w-100 isEditable" type="number" min="0" placeholder="No. de Cajones de Estacionamiento" formControlName="cajones_estacionamiento">

            <span class="mt-5 font-rb">Horario de trabajo</span>
            <label for="tamanio" class="mt-3 d-block">Lunes a viernes</label>

            <label for="inicioES" class="mt-3 d-block">Del día</label>
            <select class="w-100 isDisabled" formControlName="inicioES">
                <option value="Lunes" selected>Lunes</option>
                <option value="Martes">Martes</option>
                <option value="Miércoles">Miércoles</option>   
                <option value="Jueves">Jueves</option>  
                <option value="Viernes">Viernes</option> 
                <option value="Viernes">Sábado</option>    
            </select>  
            <label for="finES" class="mt-3 d-block">Al día</label>
            <select class="w-100 isDisabled" formControlName="finES">
               <option value="Lunes" selected>Lunes</option>
               <option value="Martes">Martes</option>
               <option value="Miércoles">Miércoles</option>   
               <option value="Jueves">Jueves</option>  
               <option value="Viernes">Viernes</option> 
               <option value="Viernes">Sábado</option>     
            </select>
            <label for="inicio_hs" class="mt-3 d-block">De las</label>
            <input class="w-100 isEditable" type="time" id="inicio_hs" min="05:00" max="22:00" formControlName="inicio_hs">
            <label for="tamanio" class="mt-3 d-block">A las</label>
            <input class="w-100 isEditable" type="time" id="fin_hs" formControlName="fin_hs">
            <label for="fin_hs" class="note-format-text font-mr d-block">No mayor de las 10:00 p.m</label>

            <span class="mt-5 font-rb">Fin de semana</span>
            <div class="col-12 mt-2 font-mr">
               <label for="horario_fin_semana1">Sábado</label>
               <select 
                  class="w-100 font-rb" 
                  formControlName="horario_fin_semana1"
                  id="saturday"
                  [ngClass]="{ 
                     'is-invalid': formRecordInfo.controls['horario_fin_semana1'].invalid && formRecordInfo.controls['horario_fin_semana1'].touched,
                     'is-valid': formRecordInfo.controls['horario_fin_semana1'].valid && formRecordInfo.controls['horario_fin_semana1'].touched }"                         
                  (click)="updateDay('saturday')"
               >                
               <option value="no">No se labora</option>   
               <option value="Sábado">Si se labora</option>                                                               
                  
               </select>
               <div class="invalid-feedback note-format-text font-mr w-100">Campo requerido.</div>
            </div>
            <div class="col-12 mt-2 font-mr">
               <label for="inicio_hfs1" [attr.hidden]="saturday ? null : '' ">De las</label>
               <input 
                  class="w-100 font-rr" 
                  type="time" 
                  name="eta" 
                  id="inicio_hfs1" 
                  formControlName="inicio_hfs1"
                  [ngClass]="{ 
                     'is-invalid': formRecordInfo.controls['inicio_hfs1'].invalid && formRecordInfo.controls['inicio_hfs1'].touched,
                     'is-valid': formRecordInfo.controls['inicio_hfs1'].valid && formRecordInfo.controls['inicio_hfs1'].touched }"  
                  [attr.hidden]="saturday ? null : '' "                        
               >
               <div class="invalid-feedback note-format-text font-mr w-100" [attr.hidden]="saturday ? null : '' ">Campo requerido.</div>
            </div>
            <div class="col-12 mt-2 font-mr">
               <label for="inicio_hfs1" [attr.hidden]="saturday ? null : '' ">A las</label>
               <input 
                  class="w-100 font-rr" 
                  type="time" 
                  name="eta" 
                  id="fin_hfs1" 
                  formControlName="fin_hfs1"
                  [ngClass]="{ 
                     'is-invalid': formRecordInfo.controls['fin_hfs1'].invalid && formRecordInfo.controls['fin_hfs1'].touched,
                     'is-valid': formRecordInfo.controls['fin_hfs1'].valid && formRecordInfo.controls['fin_hfs1'].touched }" 
                  [attr.hidden]="!saturday ? '' : null"                         
               >
               <label for="fin_hs1" class="note-format-text font-mr" [attr.hidden]="saturday ? null : '' ">No mayor a las 10:00 p.m</label>
               <div class="invalid-feedback note-format-text font-mr w-100" [attr.hidden]="saturday ? null : '' ">Campo requerido.</div>
            </div>


            <div class="col-12 mt-2 font-mr">
               <label for="horario_fin_semana1">Domingo</label>
               <select 
                  class="w-100 font-rr" 
                  formControlName="horario_fin_semana2"
                  id="sunday"
                  [ngClass]="{ 
                     'is-invalid': formRecordInfo.controls['horario_fin_semana2'].invalid && formRecordInfo.controls['horario_fin_semana2'].touched,
                     'is-valid': formRecordInfo.controls['horario_fin_semana2'].valid && formRecordInfo.controls['horario_fin_semana2'].touched }"                         
                  (click)="updateDay('sunday')"
               >                
               <option value="no">No se labora</option>   
               <option value="Domingo">Si se labora</option>                                                               
                  
               </select>
               <div class="invalid-feedback text-start w-100">Campo requerido.</div>
            </div>
            <div class="col-12 mt-2 font-mr">
               <label for="inicio_hfs2" [attr.hidden]="sunday ? null : '' ">De las</label>
               <input 
                  class="w-100 font-rr" 
                  type="time" 
                  name="eta" 
                  id="inicio_hfs2" 
                  formControlName="inicio_hfs2"
                  [ngClass]="{ 
                     'is-invalid': formRecordInfo.controls['inicio_hfs2'].invalid && formRecordInfo.controls['inicio_hfs2'].touched,
                     'is-valid': formRecordInfo.controls['inicio_hfs2'].valid && formRecordInfo.controls['inicio_hfs2'].touched }"  
                  [attr.hidden]="sunday ? null : '' "                        
               >
               <div class="invalid-feedback note-format-text font-mr w-100" [attr.hidden]="sunday ? null : '' ">Campo requerido.</div>
            </div>
            <div class="col-12 mt-2 font-mr">
               <label for="fin_hfs2" [attr.hidden]="sunday ? null : '' ">A las</label>
               <input 
                  class="w-100 font-rr" 
                  type="time" 
                  name="eta" 
                  id="fin_hfs2" 
                  formControlName="fin_hfs2"
                  [ngClass]="{ 
                     'is-invalid': formRecordInfo.controls['fin_hfs2'].invalid && formRecordInfo.controls['fin_hfs2'].touched,
                     'is-valid': formRecordInfo.controls['fin_hfs2'].valid && formRecordInfo.controls['fin_hfs2'].touched }" 
                  [attr.hidden]="!sunday ? '' : null"                         
               >
               <label for="fin_hs1" class="note-format-text font-mr" [attr.hidden]="sunday ? null : '' ">No mayor a las 10:00 p.m</label>
               <div class="invalid-feedback note-format-text font-mr w-100" [attr.hidden]="sunday ? null : '' ">Campo requerido.</div>
            </div>

            <span class="mt-5 font-rb">Anuncios y Servicio municipal de Empleo</span>
            <label for="anuncios" class="mt-3 d-block">¿Cuenta con anuncios publicitarios?</label>
            <select class="w-100 isDisabled" formControlName="anuncios">
                <option value="1" selected>Si</option>
                <option value="0">No</option>                                                       
            </select>

            <label for="servicio_empleo" class="mt-3 d-block">¿Requiere del servicio municipal de Empleo?</label>
            <select class="w-100 isDisabled" formControlName="servicio_empleo">
                <option value="1" selected>Si</option>
                <option value="0">No</option>                                                       
            </select>

            <div class="d-flex justify-content-center" *ngIf="validEditStatus.includes(originalRecordInfo?.id_estatus_expediente)">
                <button class="btn-sm btn-info mt-3 text-white" type="submit">Actualizar solicitud</button>
            </div>
        </form>       
    </div>
</div> 