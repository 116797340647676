// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
 
  firebase: {
    apiKey: "AIzaSyDfTy9Jecb81KFERLLaJqkar7g8Wdqad4Q",
    authDomain: "sare-qa.firebaseapp.com",
    projectId: "sare-qa",
    storageBucket: "sare-qa.appspot.com",
    messagingSenderId: "650827192430",
    appId: "1:650827192430:web:b2fa2253f0c0a5b317ee7f"
  },
  production: false,
  url: 'https://api-sare-qa.zurco.com.mx',
  BUCKET: '',
  API_KEY: 'NjU4OWVmODNlMGZmNWU2MmQ0ZTM3NjkyOTYyOGVmMjdmZWYxNGJiN2NlN2NkMzViYTNlYTc5Y2U2Y2E1NWVkNA==',
  CRYPTO_KEY: 'MzZmYzdjODdhZjdmNDJhMzYzM2Q3OTI1ZDY0NTg0NzVlN2ZiYzRlODgwMWQ5MTNmODZmZjc0MTRiZmQ2N2FkYWIwYTFmNGExY2E2YTQ0NjRiMDg5YTY2NGI1YjI1OWZiOGE3MjQ1YTFmM2RiZTA4YjkwYjc0ZWRiYjQzY2FlNDU='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
