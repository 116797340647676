import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-side-card-menu-general',
  templateUrl: './side-card-menu-general.component.html',
  styleUrls: ['./side-card-menu-general.component.css']
})
export class SideCardMenuGeneralComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  sendIdToParent(idNavElement:string) {
    this.messageEvent.emit(idNavElement);
  }

}
