import { Component, Input, OnInit, SimpleChanges, ElementRef } from '@angular/core';
import { DocumentService } from '@data/services/api/protected/document.service';
import { UserService } from '@data/services/api/protected/user.service';

@Component({
  selector: 'app-instructions-general',
  templateUrl: './instructions-general.component.html',
  styleUrls: ['./instructions-general.component.css']
})
export class InstructionsGeneralComponent implements OnInit {
  @Input() idNavCardInstructions: any;
  infoCatDocuments:  any;
  constructor(private documentService: DocumentService, private userService: UserService) { }
  user: any;
  async ngOnInit(): Promise<void> {
    this.infoCatDocuments = await this.documentService.getCatDocumentsByPerson();
    this.user = await this.userService.getUser();  
  }
  ngOnChanges(changes: SimpleChanges) {
    this.clickNavElement(this.idNavCardInstructions);
  }

  clickNavElement(id:string) {
    document.getElementById(id + '-tab')?.click();
  }

}
