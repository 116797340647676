<div  *ngIf="userLoged|async as user;" class="w-100">
  <div class="dropdown">
    <input type="checkbox" id="dropdown">      
    <label class="dropdown__face" for="dropdown">
      <img class="user-icon img-fluid" src="./../../../../assets/imagenes/icons/userBoton.png" alt="">
      <span class="ps-1 font-rr font-user">{{ user.displayName?user.displayName : user.email}}</span>
      <div class="my-ms dropdown__arrow"></div>
    </label>      
    <ul class="dropdown__items">
      <li (click)="logout()" class="font-user w-100">
        <i class="fa-solid fa-power-off"></i>
        <span class="font-rr">Cerrar sesión</span> 
      </li>        
    </ul>
  </div>
</div>
