<div class="card-info-content w-100 d-flex justify-content-center aligns-items-center">
    <div class="row w-100">
        <div class="col-lg-8 col-12 row">
            <div (click)="sendIdToParent('instruccionesGenerales')" class="col-12 card-info-container border d-flex justify-content-center aligns-items-center">
                <div class="row">
                    <div class="col-4 d-flex justify-content-center aligns-items-center">
                        <picture class="w-100 d-flex justify-content-center align-items-center">
                            <img class="img-responsive icon-card"  src="assets/imagenes/icons/grid.png">
                         </picture>
                    </div>
                    <div class="col-7 d-flex justify-content-center aligns-items-center">
                        <div class="d-flex align-items-center">
                            <p class="title-sub-card">Instrucciones Generales</p>
                        </div>
                    </div>
                </div>
            </div>
            <div (click)="sendIdToParent('personaMoral')" class="col-6 card-info-container border d-flex justify-content-center aligns-items-center">
                <div class="d-flex align-items-end">
                    <div>
                        <picture class="w-100 d-flex align-items-center justify-content-center">
                            <img class="img-responsive icon-card"  src="assets/imagenes/icons/maletin.png">
                         </picture>
                        <p class="title-sub-card mt-3">Persona Moral</p>
                    </div>
                </div>
            </div>
            <div (click)="sendIdToParent('personaFisica')" class="col-6 card-info-container border d-flex justify-content-center aligns-items-end">
                <div class="d-flex align-items-end">
                    <div>
                        <picture class="w-100 d-flex align-items-center justify-content-center">
                            <img class="img-responsive icon-card"  src="assets/imagenes/icons/folder.png">
                         </picture>
                        <p class="title-sub-card mt-3">Persona Física</p>
                       
                    </div>
                </div>
            </div>
        </div>
        <div (click)="sendIdToParent('bienvenida-Sare')" class="col-lg-4 col-11 card-info-container border d-flex justify-content-center aligns-items-center">
            <div class="d-flex align-items-center">
                <div>
                    <picture class="w-100 d-flex align-items-center justify-content-center">
                        <img class="img-responsive icon-card"  src="assets/imagenes/identity/logoSare.png">
                     </picture>
                    <p class="title-sub-card mt-3">SARE</p>
                    <p class="subtitle mt-3">Sistema de Apertura Rápida de Empresas</p>
                </div>
            </div>
        </div>
    </div>
</div>