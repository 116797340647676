<div class="sare-header "> 
   <div class="sare-title"><span>SARE</span></div>
   <div class="user-btn">
      <app-user-session-btn></app-user-session-btn>
   </div>
</div>






   