import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor() { }

  error(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: true,
      background: 'rgba(252,87,83,0.8)',
      customClass: {
        title: 'error-text',
        confirmButton: 'btn-error-ok'
      }
    });
    
    Toast.fire({
      confirmButtonText: 'Aceptar',
      title: title
    });
  }

  success(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: true,
      background: '#aee7f8',
      customClass: {
        title: 'success-text',
        confirmButton: 'btn-success-ok'
      }
    });
    
    Toast.fire({
      confirmButtonText: 'Aceptar',
      title: title
    });
  }

  warning(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: true,
      background: 'rgba(253,188,64,0.8)',
      customClass: {
        title: 'warning-text',
        confirmButton: 'btn-warning-ok'
      }
    });
    
    Toast.fire({
      confirmButtonText: 'Aceptar',
      title: title
    });
  }

  loading(isLoading: boolean, title: String) {
    if(isLoading) {
      Swal.fire({
        heightAuto: false,
        title: title,
        html: 'Cargando...',
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        didClose: () => {
          Swal.hideLoading();
        }
      });
    } else {
      Swal.close();
    }
  }

}
