<div class="row card-info-container" *ngIf="user != undefined"> <!--info-content -->    
    <div class="col-12 scroll scroll--simple">
      <ul class="nav nav-tabs" id="tabInfoCard" role="tablist" hidden>
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="instruccionesGenerales-tab" data-bs-toggle="tab" data-bs-target="#instruccionesGenerales" type="button" role="tab" aria-controls="instruccionesGenerales" aria-selected="true">IG</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="personaMoral-tab" data-bs-toggle="tab" data-bs-target="#personaMoral" type="button" role="tab" aria-controls="personaMoral" aria-selected="false">PM</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="personaFisica-tab" data-bs-toggle="tab" data-bs-target="#personaFisica" type="button" role="tab" aria-controls="personaFisica" aria-selected="false">PF</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="bienvenida-Sare-tab" data-bs-toggle="tab" data-bs-target="#bienvenida-Sare" type="button" role="tab" aria-controls="bienvenida-Sare" aria-selected="false">DP</button>
        </li>
      </ul>
      <div class="tab-content" id="tabInfoCardContent" *ngIf="user != undefined">
        <!-- Panel: Instrucciones Generales -->
        <div class="tab-pane fade show active" id="instruccionesGenerales" role="tabpanel" aria-labelledby="instruccionesGenerales-tab">
          <div class="instructions-header">
            <div class="new-request" *ngIf="user.info.id_rol == 2"><a href="/user/solicitud">Nueva Solicitud</a></div>
            <div class="instructions-title">
              <span class="title-1">Intrucciones</span>
              <span class="title-2">Generales</span>              
            </div>
          </div>
          <div class="accordion accordion-flush" id="accordionInstructions">
            <div class="accordion-item">
              <h3 class="accordion-header" id="pasosPmoral">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Step1" aria-expanded="true" aria-controls="Step1">
                  <div class="accordion-enum">1</div> Creación de Nueva solicitud
                </button>
              </h3>
              <div id="Step1" class="accordion-collapse collapse show" aria-labelledby="pasosPmoral" data-bs-parent="#accordionInstructions">
                  <div class="accordion-body">                    
                    <p>Para crear una nueva solicitud es necesario rellenar el Formato de apertura rápida de empresas
                       con los datos personales del solicitante y la empresa en el apartado "Nueva solicitud"</p> 
                  </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  <div class="accordion-enum">2</div> Carga de documentos
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionInstructions">
                <div class="accordion-body">
                  <p>Con base en los datos proporcionados en la solicitud de apertura se requerirá la carga de la 
                    documentación correspondiente ya sea en formato pdf, png o jpg</p> 
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                  <div class="accordion-enum">3</div> Verificación de datos
                </button>
              </h2>
              <div id="flush-collapse3" class="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionInstructions">
                <div class="accordion-body">
                  <p>Tanto los datos de la solicitud como los documentos pueden ser modificados las veces que sean necesarias 
                    hasta el momento que sea enviada la solicitud, ya que una vez sea enviada para su revisión la documentación
                     y los datos no podrán ser modificados. </p> 
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                  <div class="accordion-enum">4</div> Revisión de Solicitud
                </button>
              </h2>
              <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionInstructions">
                <div class="accordion-body">
                  <p>Una vez enviada la solicitud se puede consultar su estado en el apartado de seguimiento.
                    Los 3 posibles estados de la solicitud son:
                     </p> 
                  <ul>
                    <li>Rechazo definitivo</li>
                    <li>Con observación- Aparece cuando la solicitud necesita 
                      modificaciones en los datos o archivos, el usuario deberá complementar en forma 
                      inmediata lo requerido, para continuar el proceso de la solicitud.</li>
                    <p class="mt-1"><strong>En estos dos primeros estados es posible consultar el motivo de rechazo </strong> </p>
                    <li>En proceso- Si los datos y archivos son correctos se continua con el 
                      trámite.
                      </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                  <div class="accordion-enum">5</div> Seguimiento de Solicitud
                </button>
              </h2>
              <div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionInstructions">
                <div class="accordion-body">
                  <p>En la sección de bitácora es posible consultar el estado de la solicitud hasta la emisión de la licencia.</p> 
                </div>
              </div>
            </div>
           

           
          </div>
        </div>
        <!-- Panel: Persona Moral -->
        <div class="tab-pane fade" id="personaMoral" role="tabpanel" aria-labelledby="personaMoral-tab">
          <div class="instructions-header">
            <div class="new-request"><a href="/user/solicitud">Nueva Solicitud</a></div>
            <div class="instructions-title">
              <span class="title-1">Persona</span>
              <span class="title-2">Moral</span>              
            </div>
          </div>
          <div *ngIf="infoCatDocuments" class="accordion accordion-flush" id="accordionDocumentsMoral">
            <div *ngFor="let document of infoCatDocuments.personaMoral; let i = index;" class="accordion-item">
              <h2 class="accordion-header" id="moralDocument{{i}}">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#myMoralDucument'+i" aria-expanded="false" aria-controls="myMoralDucument">
                   <div class="accordion-enum">{{i+1}}</div> {{document?.nombre_documento}}
                </button>
              </h2>
              <div id="myMoralDucument{{i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'moralDocument'+i"  data-bs-parent="#accordionDocumentsMoral">
                <div class="accordion-body">
                  {{document.descripcion}}
                </div>
              </div>
            </div>               
          </div>
        </div>
        <!-- Panel: Persona Fisica -->
        <div class="tab-pane fade" id="personaFisica" role="tabpanel" aria-labelledby="personaFisica-tab">
          <div class="instructions-header">
            <div class="new-request"><a href="/user/solicitud">Nueva Solicitud</a></div>
            <div class="instructions-title">
              <span class="title-1">Persona</span>
              <span class="title-2">Física</span>              
            </div>
          </div>
          <div *ngIf="infoCatDocuments" class="accordion accordion-flush" id="accordionFlushExample2">
            <div *ngFor="let document of infoCatDocuments.personaFisica; let i = index;" class="accordion-item">
              <h2 class="accordion-header" id="flush{{i}}">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#fisica'+i" aria-expanded="false" [attr.aria-controls]="'fisica'+i">
                   <div class="accordion-enum">{{i+1}}</div> {{document?.nombre_documento}}
                </button>
              </h2>
              <div id="fisica{{i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'flush'+i"  data-bs-parent="#accordionFlushExample2">
                <div class="accordion-body">
                  {{document.descripcion}}
                </div>
              </div>
            </div>               
          </div>  
        </div>
        <!-- Panel: Datos Personales -->
        <div class="tab-pane fade p-0 m-0" id="bienvenida-Sare" role="tabpanel" aria-labelledby="bienvenida-Sare-tab">
          <div class="instructions-header">
            <div class="new-request"><a href="/user/solicitud">Nueva Solicitud</a></div>
            <div class="instructions-title">
              <span class="title-1">Bienvenido a</span>
              <span class="title-2">SARE</span>
              <span class="title-3">Sistema de Apertura Rápida de Empresas</span>
            </div>
          </div>
          <div class="row instructions-row p-0 m-0 mt-4 mb-4">
             <div class="col-lg-6 col-md-11 col-sm-11 p-0 m-0 sare-text">
             
                Es un sistema de simplificación de trámites municipales para el inicio 
                de operaciones de una empresa. Aquí podras encontrar la información 
                necesaria para realizar el trámite denominado: 
                <p class="blue-text mt-2 text-center">“Licencia de funcionamiento”</p>
                Siempre y cuando el giro comercial se encuentre dentro del catálogo de 
                giros de bajo impacto habilitado para su <a class="catalogo" href="/assets/files/CatalogoGirosSARE.xlsx" target="_blank">consulta.</a>
                
              
             </div>
             <div class="col-lg-6 col-md-11 col-sm-6">
              <img class="img-fluid instruction-img" src="./assets/imagenes/banners/instructions-card.png" alt="">
             </div>
          </div>
          <div class="row instructions-row p-0 m-0">
            <div class="col-lg-5 col-md-11 col-sm-11 remember-card mt-1 p-3 ">
              <img class="img-fluid" src="./assets/imagenes/icons/bell-icon.png" alt="">
              <span class="mt-2">Recuerda</span>
              <div class="mt-2 sare-text">                      
                 Si cumples con todos los requisitos, tu licencia de funcionamiento 
                 será liberada de manera digital en este mismo portal y la podrás 
                 imprimir en forma inmediata.
              </div>                             
            </div>
            <div class="col-lg-5 col-md-11 col-sm-11 remember-card refrendo-card mt-1 p-3 ">
              <img class="img-fluid" src="./assets/imagenes/icons/clock-icon.png" alt="">
              <span class="mt-2">Refrendo</span>
              <div class="mt-2 sare-text">                      
                No olvides refrendar tu licencia anualmente durante los meses de enero, 
                febrero y marzo, acudiendo en forma presencial al módulo de licencias de 
                funcionamiento.
              </div>                             
            </div>            
          </div> 
        </div>
      </div>
    </div>  
  </div>

  
  