<div class="row" >  
    <!-- Inicio carga de información -->     
    <div class="col-lg-7 col-md-12 col-sm-12 p-0">
       <form [formGroup]="formProfile" (ngSubmit)="onSubmit()" *ngIf="initialInfo != undefined">
          <div class="row p-5">
             <div class="col-12 p-0 font-mb">Información</div>
             <div class="col-12 p-0">
                <input type="text" class="mt-5 w-100" placeholder="Nombre(s)" formControlName="nombres" id="nombres">
             </div> 
             <div class="col-lg-6 col-sm-12 p-0 pe-2 mt-5">
                <input type="text" class="w-100" placeholder="Apellido Paterno" formControlName="apellido_paterno">
             </div>
             <div class="col-lg-6 col-sm-12 p-0 ps-2 mt-5">
                <input type="text" class="w-100" placeholder="Apellido Materno" formControlName="apellido_materno">
             </div>
             <div class="col-lg-6 col-sm-12 col pe-2 mt-5">
                <input type="text" class="w-100" placeholder="Teléfono fijo" formControlName="telefono_fijo">
             </div>
             <div class="col-lg-6 col-sm-12 ps-2 mt-5">
                <input type="text" class="w-100" placeholder="Celular" formControlName="telefono_celular">
             </div>
             <div class="col-lg-6 col-md-6 col-sm-12 p-0 mt-5">
                <input type="text" class="w-100" placeholder="Correo de notificación" formControlName="email">
             </div>
             <div class="col-lg-6 col-md-6 col-sm-12 p-0  mt-6 d-flex justify-content-center">
                <button class="btn btn-send-button font-mb" [disabled]="formProfile.invalid">Actualizar</button>
             </div>
          </div>
       </form>
    </div>  
    <!-- fin carga de información -->  
    <!-- Inicio carga de archivos -->  
    <div class="col-lg-5 col-md-12 col-sm-12 d-flex flex-column text-center upload-card p-0">
       <div class="input-group mb-3 input-file d-flex justify-content-center w-80 mt-4"> 
          <label class="input-group-text img-fluid d-flex justify-content-center w-70" for="inputGroupFile01">
             <img class="img-fluid" src="assets/imagenes/icons/upload.png" alt="">                
          </label>     
       </div>
       <input type="file" class="form-control" #documentInput id="inputGroupFile01" accept=".jpeg, .jpg, .png" hidden (input)="uploadFile($event)">             
       <!--<div class="row mt-3 w-100 p-0 m-0 d-flex justify-content-center" >           
          <button class="btn btn-send-button font-mb">Subir</button>            
       </div>-->
    </div>  
    <!-- fin carga de archivos -->          
 </div>   
 
    
    
