import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignGuard } from '@core/guards/auth/sing.guard';
import { ToDashboardGuard } from '@core/guards/auth/to_dashboard.guard';


const routes: Routes = [

  {
    path: 'auth',
    loadChildren: () => import('@modules/auth/auth.module').then( m => m.AuthModule ),
   // canLoad: [ ToDashboardGuard ]
    
  },
  /*
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then( m => m.PublicModule ),
  },
  */
  {
    path: '',
    loadChildren: () => import('./modules/pages/protected.module').then( m => m.ProtectedModule ),
    canLoad: [ SignGuard ]
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { 
 
}
