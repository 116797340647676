import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FireStorageService } from '@data/services/api/protected/fire-storage.service';
import { UserService } from '@data/services/api/protected/user.service';
import { UiService } from '@shared/services/ui.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-digital-profile-general',
  templateUrl: './digital-profile-general.component.html',
  styleUrls: ['./digital-profile-general.component.css']
})
export class DigitalProfileGeneralComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private uiService: UiService,
    private storageService: FireStorageService,
    private authService: AngularFireAuth,
    @Inject(DOCUMENT) document: Document
  ) { }

  user: any;
  async ngOnInit(): Promise<void> {
    this.user = await this.userService.getUser();
    if(this.user.info){
      this.load();
    } else {
      this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.')
    }
  }
  load(): any{
    const response = {
      nombres:                   this.user.info.nombre,
      apellido_paterno:          this.user.info.apellido_paterno,
      apellido_materno:          this.user.info.apellido_materno,      
      telefono_fijo:             this.user.info.telefono_fijo,
      email:                     this.user.info.correo,
      telefono_celular:          this.user.info.telefono_celular
    }
    this.formProfile.patchValue(response);    
  }
  initialInfo: any = {
    infoUser: {
      nombres: "Hugo Erik",
      apellido_materno: "Ibáñez",
      apellido_paterno: "Castañeda",
      email: "hugocastaneda@zurco.com.mx",      
      telefono_celular: "55 307 566 75",
      telefono_fijo: "55 307 566 75",     
    }
  }
  formProfile: FormGroup = this.formBuilder.group({
    // Datos personales 
    nombres         :         [null, [Validators.required]],
    apellido_paterno:         [null, [Validators.required]],
    apellido_materno:         [null, [Validators.required]],
    email:                    [null, [Validators.required, Validators.email]],
    telefono_celular:         [null, [Validators.required]],
    telefono_fijo:            [null, [Validators.required]]    
  });

  async onSubmit(): Promise<void>{
    if(this.formProfile.valid) {
      this.uiService.loading(true, 'Generando solicitud');
      let response:any = await this.userService.updateUser(this.formProfile);
      if(response?.ok){
        this.uiService.success('La información se actualizó correctamente.');
        this.ngOnInit();
      } else {
        this.uiService.error('Ocurrió un error al actualizar la información, por favor intente más tarde.');
      }
    } else {
      this.uiService.success('Debe completar el formulario y aceptar los términos y condiciones para continuar');
    }
  } 

  /*************** Carga de documentos a Firestorage ****************/
  @ViewChild('profileImageInput')
  documentInput!: ElementRef;

  dataFileUpload:any = {
    name:'profileImage',
    file: null
  }
  
  uploadFile(event:any){
    this.dataFileUpload.file = event.target.files[0];
    if(typeof this.dataFileUpload.file !== 'undefined') {
      this.uploadToFirestorage();
    }
  }

  async uploadToFirestorage(){ 
    this.uiService.loading(true, 'Subiendo archivo');
    this.getBase64(this.dataFileUpload.file).then( async data => {
      let user = await firstValueFrom(this.authService.user);  
      this.storageService.uploadFile(this.dataFileUpload.name, `usuarios/${user?.uid}/`, data).then(async responseStorage =>{
        if(responseStorage != null){ 
          let updateImageResponse:any = await this.userService.updateImageProfile(responseStorage);
          if(updateImageResponse?.ok){
            this.uiService.success('La foto de perfil se actualizó correctamente.');
          } else {
            this.uiService.success('Ocurrio un error al tratar de procesar la solicitud, por favor inténtelo más tarde.');
          }
        } else {
          this.uiService.success('Ocurrio un error al procesar la solicitud, por favor inténtelo más tarde.');
        } 
      });
    });
  }

  getBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}
